import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiDataContext } from '../../context/ApiDataContext';
import ReactHelmetTag from '../commonComponents/reactHelmet/ReactHelmetTag';
import Megamenu from '../megamenu/Megamenu';
import './ProductPricingList.css'
import useWindowSize from '../commonComponents/commonFunction/UseWindowSize';

function ProductPricingList() {
    const { productPageData, theme } = useContext(ApiDataContext);
    const [activePlan, setActivePlan] = useState('Yearly');
    const { windowWidth } = useWindowSize();

    useEffect(() => {
        document.title = "Price List";
        window.scrollTo(0, 0);
    }, []);

    function getLogoForProduct(productName) {
        switch (productName) {
            case 'Asset Management System':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/ams-w.png') : require('../../assets/images/icons/productIcons/ams-b.png');
            case 'Learning Management Portal':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/lmp-w.png') : require('../../assets/images/icons/productIcons/lmp-b.png');
            case 'Audit Inspection':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/audit-w.png') : require('../../assets/images/icons/productIcons/audit-b.png');
            case 'Construction Management System':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/cms-w.png') : require('../../assets/images/icons/productIcons/cms-b.png');
            case 'SPE Intranet':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/spe-Intranet-w.png') : require('../../assets/images/icons/productIcons/spe-Intranet-b.png');
            case 'HelpDesk System':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/hd-w.png') : require('../../assets/images/icons/productIcons/hd-b.png');
            case 'Inventory Management System':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/ims-w.png') : require('../../assets/images/icons/productIcons/ims-b.png');
            case 'Approval System':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/as-w.png') : require('../../assets/images/icons/productIcons/as-b.png');
            default:
                return null;
        }
    };

    function getViewProduct(productName) {
        switch (productName) {
            case 'Asset Management System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005193?exp=ubp8';
            case 'Learning Management Portal':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005182?exp=kyyw';
            case 'Audit Inspection':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005564?exp=ubp8';
            case 'Construction Management System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005398?exp=kyyw';
            case 'SPE Intranet':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005709';
            case 'HelpDesk System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005785';
            case 'Inventory Management System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200006100';
            case 'Approval System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200006514?tab=Overview';
            default:
                return null;
        }
    };

    return (
        <div>
            <Megamenu />
            <ReactHelmetTag link="https://www.sharepointempower.com/product-pricing-list" />
            <div className="fixed-top-container">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div id="first-section-margin-top-for-navbar">
                                    <h1 className="h1-tag-lm h1-heading">Product Pricing List</h1>
                                </div>
                            </div>
                            <div className="col col-4 col-sm-4 col-md-6 col-lg-6 col-xl-6 col-xxl-6 flex justify-content-end align-items-center">
                                <div id="first-section-margin-top-for-navbar" className="faq-button d-flex">
                                    <Link to="/faqs" className='mr-2'>
                                        <i className="pi pi-question-circle mr-1" />
                                        <span className='m-0 '>FAQs</span>
                                    </Link>
                                    <Link to='/contact-us' className='pricing-quotation'>
                                        <span className='m-0'>Get Quotation</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container">
                    <table className="pricing-list-table">
                        <thead>
                            <tr>
                                <th className='table-th' scope="col">Product</th>
                                <th className='table-th' scope="col">Description</th>
                                <th className="pricing-plan-content table-th" scope="col">
                                    <div className={windowWidth > 765 ? "toggle-button-container" : "toggle-button-container-mobile"}>
                                        <button
                                            className={`toggle-button ${activePlan === 'Yearly' ? 'active' : ''}`}
                                            onClick={() => setActivePlan('Yearly')}
                                        >
                                            Yearly
                                        </button>
                                        <button
                                            className={`toggle-button ${activePlan === 'Quarterly' ? 'active' : ''}`}
                                            onClick={() => setActivePlan('Quarterly')}
                                        >
                                            Quarterly
                                        </button>
                                    </div>
                                </th>
                                <th {...(windowWidth < 480 ? { rowSpan: "2" } : { colSpan: "2" })} className='table-th btn-width pricing-plan-content' scope="col">Get it now</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productPageData?.map((product, index) => {
                                let updatedTitle = product?.projectTitle

                                if (product.projectTitle?.includes('Management') && product.projectTitle?.includes('System')) {
                                    updatedTitle = product.projectTitle.replace('System', '').trim();
                                }

                                return product.displayOrder === 0 ? null : (
                                    <tr key={index}>
                                        <td className="pricing-product-title-col">
                                            <div className={windowWidth < 480 ? 'flex flex-column align-items-center' : ''}>
                                                <img src={getLogoForProduct(product.projectTitle)} className='price-product-icon' alt="loading"></img>
                                                <a
                                                    className="pricing-product-title"
                                                    href={product?.routPath}
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                    tabIndex="-1"
                                                    aria-disabled="true"
                                                >
                                                    <span className='text-nowrap'>{updatedTitle}</span>
                                                </a>
                                                <img src={theme === 'light' ? require('../../assets/images/microsoftSolutionsPartner/microsoft-badge-white.png') : require('../../assets/images/microsoftSolutionsPartner/microsoft-badge-black.png')} alt="loading" className='price-badge-icon' />
                                            </div>
                                        </td>
                                        <td className="pricing-p-col">
                                            <p className="pricing-p" >{product?.productKnowDescription?.slice(0, 105)}
                                                <Link
                                                    to={product?.routPath}
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                    tabIndex="-1"
                                                    aria-disabled="true"
                                                    className='text-xs ps-3'
                                                >
                                                    ...  Read More
                                                </Link>
                                            </p>
                                        </td>
                                        <td className="pricing-plan-content" >
                                            <p className="product-price"> {activePlan === 'Yearly' ? product.plan : product.quarterlyPlan}</p>
                                            <p className="pricing-p">{(activePlan === 'Yearly' ?
                                                product?.planDescription.replace('*Billed Quarterly', '*Billed Annually') :
                                                product?.planDescription.replace('*Billed Annually', '*Billed Quarterly'))}</p>
                                        </td>
                                        <td className="pricing-plan-content">
                                            <Link to='/order-page' className="pricing-card-button">
                                                {windowWidth > 1024 ? 'Buy Now' : (<i className="pi pi-shopping-cart" />)}
                                            </Link>
                                        </td>
                                        <td className="pricing-plan-content">
                                            <Link to={getViewProduct(product.projectTitle)} target='_blank' className="pricing-card-button">
                                                {windowWidth > 1024 ? 'Explore' : (<i className="pi pi-download" />)}
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <br />
                    {productPageData?.map((product, index) => (
                        product.displayOrder === 0 && (
                            <div key={index}>
                                <p className="p-center-content-lm">
                                    <div>
                                        <h6 className="h5-title-lm text-align-center h5-title-anim">
                                            {product.projectTitle}
                                        </h6>
                                    </div>
                                </p>
                                <p className="p-center-content-lm">{product.description}</p>
                                <p className="p-center-content-lm">{product.productDescription}</p>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div >
    );
};

export default ProductPricingList;