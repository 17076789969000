import React, { useEffect, useState, useContext, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactHelmetTag from '../reactHelmet/ReactHelmetTag';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { format } from 'date-fns';
import { Checkbox } from "primereact/checkbox";
import { Link } from 'react-router-dom';
import { ApiDataContext } from '../../../context/ApiDataContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import CryptoJS from 'crypto-js';
import Megamenu from '../../megamenu/Megamenu';
import './PaymentGateway.css';

const PaymentGateway = () => {

    const { productPageData } = useContext(ApiDataContext);
    const [errorDialog, setErrorDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const [newInvoiceNumber, setNewInvoiceNumber] = useState(null);
    const [charCount, setCharCount] = useState(0);
    const [billingDuration, setBillingDuration] = useState('Yearly');

    const form = useRef();
    const titlesAndPlans = productPageData?.map(({ projectTitle, planDescription, plan, certifiedImage, quarterlyPlan }) => {
        const price = parseInt(plan.match(/\d+/)[0], 10);
        const quarterlyPrice = parseInt(quarterlyPlan.match(/\d+/)[0], 10);
        return { projectTitle, planDescription, price, certifiedImage, quarterlyPrice };
    });

    useEffect(() => {
        document.title = "Order Page";
        window.scrollTo(0, 0);
    }, []);

    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        userCompany: '',
        userCompanyAddress: '',
        userMobileNo: 0,
        userService: [],
        CustomizationDetail: [],
        subTotal: 0,
        GrandTotal: 0
    });

    const closeErrorDialog = () => setErrorDialog(false);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        const generateSixDigitNumber = () => {
            return Math.floor(100000 + Math.random() * 900000);
        };

        const randomInvoiceNumber = generateSixDigitNumber();
        setNewInvoiceNumber(randomInvoiceNumber);
    }, []);

    const compareObjects = async (_saveRawdata, result) => {
        const resultData = {
            OrderId: result.order_id,
            Amount: result.sdk_payload.payload.amount,
            CustomerEmail: result.sdk_payload.payload.customerEmail,
            CustomerPhone: result.sdk_payload.payload.customerPhone,
            CustomerAddress: result.sdk_payload.payload.udf4,
            payment_page_client_id: result.sdk_payload.payload.clientId,
            action: result.sdk_payload.payload.action,
            Currency: result.sdk_payload.payload.currency,
            FirstName: result.sdk_payload.payload.firstName,
            LastName: result.sdk_payload.payload.lastName,
            ReturnUrl: result.sdk_payload.payload.returnUrl,
        };

        return JSON.stringify(_saveRawdata) == JSON.stringify(resultData);
    };

    const secretKey = "sh@repoint1234567890sh@repoint#$";
    const iv = CryptoJS.enc.Utf8.parse("sh@repoint1234#$");

    function encryptData(data) {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(secretKey), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encrypted.toString();
    };

    const validateForm = async () => {
        setSubmitted(true);

        // Validate form inputs
        const isFormInvalid = [
            !formData.userName.trim(),
            !isValidEmail(formData.userEmail.trim()),
            !formData.userCompany.trim(),
            !formData.userCompanyAddress.trim(),
            !formData.userMobileNo,
            formData.userService.length === 0,
        ].some(Boolean);

        if (isFormInvalid) return;

        setBlocked(true);

        // Define headers
        const customerHeader = new Headers({
            "Content-Type": "application/json;odata=verbose",
            "Authorization": "Bearer 4352345342"
        });

        const myHeaders = new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*"
        });

        const todayDate = new Date();
        const formattedDate = format(todayDate, 'ddMMyyHHmmssSSS');
        const customerFormattedDate = format(todayDate, 'ddMMyyHHmm');

        const customerApiData = {
            Title: formData.userName,
            EmailAddress: formData.userEmail,
            Companyname: formData.userCompany,
            Companylocation: formData.userCompanyAddress,
            Date: todayDate,
            PaymentDate: todayDate,
            Total: formData.GrandTotal,
            SelectType: "Proforma Invoice",
            Discount: "0",
            Note: "",
            BillDuration: billingDuration,
            BalanceOwned: formData.GrandTotal,
            MoveToClientTracker: "False",
            CurrencyId: "1",
            CustomizationDetail: JSON.stringify(formData.CustomizationDetail),
            Price: JSON.stringify(formData.userService),
            RandomBillNO: `proforma_${newInvoiceNumber}`,
            PaymentMode: "false",
            PurchaseOrder: `proforma_${newInvoiceNumber}`,
            Comments: `OrderId : ${formattedDate}, Total: ${formData.GrandTotal} USD, Date: ${todayDate}`,
            BankDetails: "HDFC smart gateway"
        };

        const rawData = {
            OrderId: formattedDate,
            Amount: formData.GrandTotal,
            CustomerId: customerFormattedDate,
            CustomerEmail: formData.userEmail,
            CustomerPhone: formData.userMobileNo,
            CustomerAddress: formData.userCompanyAddress,
            payment_page_client_id: "hdfcmaster",
            // "payment_page_client_id": "37692",
            action: "paymentPage",
            Currency: "USD",
            FirstName: formData.userName,
            LastName: formData.userCompany,
            // "ReturnUrl": "https://api.sharepointempower.com/api/sharepoint/postOrderStatus"
            "ReturnUrl": "https://apiqa.sharepointempower.com/api/sharepoint/qaPostOrderStatus"
            // "ReturnUrl": "http://localhost:5220/api/sharepoint/qaPostOrderStatus"
        };

        try {
            const invoiceResponseMethod = {
                method: "POST",
                headers: customerHeader,
                body: JSON.stringify(customerApiData)
            }
            // const invoiceResponse = await fetch("https://api.sharepointempower.com/api/sharepoint/postInvoiceData", invoiceResponseMethod);
            const invoiceResponse = await fetch("https://apiqa.sharepointempower.com/api/sharepoint/qaPostInvoiceData", invoiceResponseMethod);
            // const invoiceResponse = await fetch("http://localhost:5220/api/SharePoint/qaPostInvoiceData", invoiceResponseMethod);
            if (!invoiceResponse.ok) {
                throw new Error("Failed to post invoice data");
            }

            const encryptedData = encryptData(rawData);

            // const paymentResponse = await fetch("https://api.sharepointempower.com/api/sharepoint/postpaymentgetway", {
                const paymentResponse = await fetch("https://apiqa.sharepointempower.com/api/sharepoint/qaPostpaymentgetway", {
                // const paymentResponse = await fetch("http://localhost:5220/api/sharepoint/qaPostpaymentgetway", {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({ data: encryptedData })
            });

            if (!paymentResponse.ok) {
                throw new Error("Failed to post payment data");
            }

            const responseData = await paymentResponse.json();
            const result = JSON.parse(responseData);

            const saveRawdata = { ...rawData };
            delete saveRawdata.CustomerId;

            const isValid = await compareObjects(saveRawdata, result);

            if (result?.payment_links?.web && isValid) {
                window.open(result.payment_links.web, '_self');
            } else {
                setErrorDialog(true);
            }
        } catch (error) {
            console.error("API Error:", error);
            setErrorDialog(true);
        } finally {
            // Reset form and state
            form.current.reset();
            setFormData({
                userName: "",
                userEmail: "",
                userCompany: "",
                userCompanyAddress: "",
                userMobileNo: "",
                userService: [],
                CustomizationDetail: [],
                subTotal: 0,
                GrandTotal: 0
            });
            setIsChecked(false);
            setSubmitted(false);
        }
        setBlocked(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'userCompanyAddress') {
            setCharCount(value.length);
        }
    };

    const updateCustomizationDetails = (selectedServices, newDuration) => {

        const duration = newDuration || billingDuration;

        const updatedCustomizationDetail = selectedServices?.map(service => ({
            Customization: `M365 - ${service.projectTitle} * ${service.planDescription}`,
            Charges: duration === 'Yearly' ? service.price * 12 : duration === 'Quarterly' ? service.quarterlyPrice * 3 : service.price,
        }));

        const total = updatedCustomizationDetail?.reduce((total, item) => total + (item.Charges || 0), 0) || 0;
        const transactionCharge = total * 0.05;
        updatedCustomizationDetail.push({
            Customization: "Credit card fees / Platform fees / Conversion charges",
            Charges: parseFloat(transactionCharge.toFixed(2)),
        });

        function formatNumber(num) {
            let str = num.toFixed(2);

            if (str.endsWith(".00")) {
                str = str.slice(0, -1);
            } else if (str.endsWith("0")) {
                str = str.slice(0, -1);
            }

            return str;
        }

        const finalTotal = parseFloat(total + transactionCharge);

        setFormData(prevFormData => ({
            ...prevFormData,
            userService: selectedServices,
            CustomizationDetail: updatedCustomizationDetail,
            transactionCharge: transactionCharge,
            subTotal: total,
            GrandTotal: `${formatNumber(finalTotal)}`,
        }));

        localStorage.setItem('orderData', JSON.stringify({
            userService: selectedServices,
            CustomizationDetail: updatedCustomizationDetail,
            Companylocation: formData.userCompanyAddress,
            totalPriceValue: finalTotal,
            GrandTotal: formatNumber(finalTotal),
            InvoiceNumber: newInvoiceNumber,
            BillDuration: billingDuration,
        }));
    };

    const handleMultiSelectChange = (e) => {
        const selectedServices = e.value;
        updateCustomizationDetails(selectedServices, billingDuration);
    };

    const handleFrequencyChange = (e) => {
        const newDuration = e.value;
        if (formData.userService?.length) {
            updateCustomizationDetails(formData.userService, newDuration);
        }
        setBillingDuration(newDuration);
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.checked);
    };

    return (
        <>
            <Megamenu />
            {blocked && <div className='payment-progress-spinner'>
                <ProgressSpinner />
            </div>}
            <div className='mt-7'>
                <ReactHelmetTag
                    link="https://www.sharepointempower.com/order-page"
                    metaKeywords="contact us form"
                />
                <div className="fixed-top-container">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col col-9 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div id="first-section-margin-top-for-navbar">
                                        <h1 className="h1-tag-lm h1-heading">Proceed with Product Payment</h1>
                                    </div>
                                </div>
                                <div className="col col-3 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 flex justify-content-end align-items-center">
                                    <div id="first-section-margin-top-for-navbar" className="faq-button d-flex">
                                        <Link to="/faqs">
                                            <i className="pi pi-question-circle mr-1" />
                                            <span className='m-0'>FAQs</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="container mt-4">
                        <div className="form-container py-0">
                            <div className="row">
                                <div className="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 pt-0">
                                    <form ref={form} name="ContactForm" autoComplete='off'
                                        className="payment-form form-data-anim lg:pl-6">
                                        <div className='flex row'>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label className='font-semibold' htmlFor="fname">Name <span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" style={{ fontSize: '0.855rem', fontWeight: 500 }} id="fname" name="userName" placeholder='Enter Your Name' onChange={handleChange} required />
                                                {submitted && !formData?.userName && <small className="p-error"> Please enter your name</small>}
                                            </div>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label className='font-semibold' htmlFor="email">Company Email Address <span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" style={{ fontSize: '0.855rem', fontWeight: 500 }} id="email" name="userEmail" placeholder='Enter Company Email Address' onChange={handleChange} required />
                                                {submitted && !formData?.userEmail && <small className="p-error"> Please enter your valid email</small>}
                                                {submitted && formData?.userEmail && !isValidEmail(formData.userEmail) && <span className="p-error">Invalid email address.</span>}
                                            </div>
                                        </div>
                                        <div className='flex row'>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label className='font-semibold' htmlFor="company">Your Company <span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" style={{ fontSize: '0.855rem', fontWeight: 500 }} id="company" name="userCompany" placeholder='Enter Your Organization Name' onChange={handleChange} required />
                                                {submitted && !formData?.userCompany && <small className="p-error"> Please enter your company name</small>}
                                            </div>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label className='font-semibold' htmlFor="mobileno">Mobile No <span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" style={{ fontSize: '0.855rem', fontWeight: 500 }} maxLength={15} placeholder='Enter Your Mobile Number' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} id="mobileno" name="userMobileNo" onChange={handleChange} required />
                                                {submitted && !formData?.userMobileNo && <small className="p-error"> Please enter your Mobile Number</small>}
                                            </div>
                                        </div>
                                        <div className='flex row'>
                                            <div className='col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 flex flex-column'>
                                                <label className='font-semibold' htmlFor="companyAdderss">Your Billing Address <span style={{ color: 'red' }}>*</span></label>
                                                <textarea style={{ fontSize: '0.855rem', fontWeight: 500 }} id="companyAdderss" name="userCompanyAddress" placeholder='This address will print on your invoice' onChange={handleChange} maxLength={255} required />
                                                <div className='flex justify-content-end' style={{ fontSize: '0.8rem', color: 'gray' }}>
                                                    {charCount}/255
                                                </div>
                                                {submitted && !formData?.userCompanyAddress && <small className="p-error"> Please enter your billing address</small>}
                                            </div>
                                        </div>
                                        <div className="flex row">
                                            <div>
                                                <div className='flex flex-row mt-1'>
                                                    <label className="font-semibold">Subscription Duration:</label>
                                                    <div className="ml-2">
                                                        <RadioButton
                                                            inputId="yearly"
                                                            name="billingDuration"
                                                            value="Yearly"
                                                            onChange={handleFrequencyChange}
                                                            checked={billingDuration === 'Yearly'}
                                                        />
                                                        <span htmlFor="yearly" className="ml-1">
                                                            Yearly
                                                        </span>
                                                    </div>
                                                    <div className="ml-2">
                                                        <RadioButton
                                                            inputId="quarterly"
                                                            name="billingDuration"
                                                            value="Quarterly"
                                                            onChange={handleFrequencyChange}
                                                            checked={billingDuration === 'Quarterly'}
                                                        />
                                                        <span htmlFor="quarterly" className="ml-1">
                                                            Quarterly
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex row">
                                            <div className='mt-3'>
                                                <label className='font-semibold' htmlFor="service">What kind of products are you looking for? <span style={{ color: 'red' }}>*</span></label>{submitted && !formData?.userService.length > 0 && <small className="p-error"> Please select product</small>}
                                                <br></br>
                                                <MultiSelect
                                                    value={formData.userService}
                                                    options={titlesAndPlans}
                                                    onChange={handleMultiSelectChange}
                                                    optionLabel="projectTitle"
                                                    display="chip"
                                                    style={{ fontSize: '0.855rem', fontWeight: 500 }}
                                                    className='payment-page-product-select'
                                                    placeholder="Select Products"
                                                    filter
                                                    itemTemplate={(option) => (
                                                        <>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <span>{option.projectTitle}</span>
                                                                <img src={option.certifiedImage || ''} alt={option.projectTitle || ''} style={{ width: 130, height: 22, marginLeft: 8 }} />
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                                <div>
                                                    {formData.userService?.length > 0 ? (
                                                        <table className="mt-3 w-full">
                                                            <thead>
                                                                <tr>
                                                                    <th className="px-2 py-2 border-bottom-1">List of Products</th>
                                                                    <th className="px-2 py-2 border-bottom-1">Price/{billingDuration}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {formData.userService?.map((service, index) => (
                                                                    <tr key={index} className="border-bottom-1">
                                                                        <td className="px-2 py-2">
                                                                            {service.projectTitle} {(billingDuration === 'Yearly' ?
                                                                                service.planDescription.replace('*Billed Quarterly', '*Billed Annually') :
                                                                                service.planDescription.replace('*Billed Annually', '*Billed Quarterly'))}
                                                                        </td>
                                                                        <td className="px-2 py-2 text-right">${billingDuration === 'Yearly' ? (service.price * 12).toFixed(2) : (service.quarterlyPrice * 3).toFixed(2)}</td>
                                                                    </tr>
                                                                ))}

                                                                <tr>
                                                                    <td className="px-2 py-2 font-italic font-bold text-right">Sub Total</td>
                                                                    <td className="px-2 py-2 text-right font-bold">${formData.subTotal?.toFixed(2)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="px-2 py-2 font-italic font-bold text-right text-sm">*Credit card fees / Platform fees / Conversion charges</td>
                                                                    <td className="px-2 py-2 text-right font-bold text-sm">${formData.transactionCharge?.toFixed(2)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="px-2 py-2 font-italic font-bold text-right text-lg">Grand Total</td>
                                                                    <td className="px-2 py-2 font-bold text-right text-lg">${(parseFloat(formData.GrandTotal)).toFixed(2)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <p style={{ fontSize: '0.755rem', fontWeight: 500 }}>No services selected</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-content-between align-items-center contact-btn-flex flex-wrap'>
                                            <div>
                                                <Checkbox onChange={handleCheckboxChange} checked={isChecked} /><span className='text-xs pl-2'> I agree with the <Link to='/legal-terms-of-use' className='text-blue-500'>Refund policy</Link> and the <Link to='/privacy-policy' className='text-blue-500'>Privacy policy.</Link></span>
                                            </div>
                                            <button type='button' className={`button-gradient ${!isChecked ? 'no-hover' : ''}`} id="btn-submit" onClick={validateForm} disabled={!isChecked}>
                                                Proceed
                                            </button>
                                        </div>
                                    </form>
                                    <Dialog
                                        visible={errorDialog}
                                        style={{ width: '350px' }}
                                        header="Error"
                                        modal
                                        onHide={closeErrorDialog}
                                        footer={<button onClick={closeErrorDialog} className="p-button p-component">OK</button>}
                                    >
                                        <p>Something went wrong, please try again.</p>
                                    </Dialog>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="form-img-container pt-4">
                                        <LazyLoadImage
                                            src={require("../../../assets/images/paymentPage/payment-form-img.jpeg")}
                                            alt="payment-form"
                                            effect="blur"
                                            visibleByDefault={require("../../../assets/images/paymentPage/payment-form-img.jpeg")}
                                        />
                                    </div>
                                    <div className='justify-content-center pt-3 payment-page-support'>
                                        <h5 className='flex justify-content-center font-bold'>Need assistance? Talk to an expert!</h5>
                                        <h6 className='flex justify-content-center align-items-center'>
                                            <i className="pi pi-envelope mr-1"></i>
                                            <a href="mailto:support@sharepointempower.com">support@sharepointempower.com</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </>
    );
};

export default PaymentGateway;